import React from 'react';
import Layout from '../Layout';
import { Section } from '../Section';
import useStoryblok from '../../../../lib/storyblok';

import Bloks from '../../../atoms/Bloks';
import BlogPreviewList from '../../organisms/BlogPreviewList';

type Props = {
  location: Location;
  pageContext: any;
};

const LandingPageWithBlogListTemplate: React.FC<Props> = ({ location, pageContext }) => {
  let story = pageContext.story;
  story = useStoryblok(story, location);
  const { content } = story;

  return (
    <Layout location={location} headerData={content.header} footerData={content.footer}>
      <Bloks bloks={content.SEO} />
      <Section curved hero noGrid curveColor="tertiary">
        <Bloks bloks={content.hero} />
      </Section>

      <Bloks bloks={content.sections} />

      {content.blogs?.lenght ? (
        <BlogPreviewList title={content.blogsTitle} blogsData={content.blogs} />
      ) : null}
    </Layout>
  );
};

export default LandingPageWithBlogListTemplate;
