import React from 'react';
import Grid from '../../atoms/Grid';
import BlogPostPreview from '../../molecules/BlogPostPreview';

type Props = {
  title: string;
  blogsData: {
    full_slug: string;
    content: {
      header: { header: string; image: any }[];
    };
  }[];
};

const BlogPreviewList: React.FC<Props> = ({ title, blogsData }) => {
  return (
    <div className="flex flex-col items-center px-5 lg:px-6 max-w-screen-xl mx-auto">
      <h3 className="col-span-full my-11 lg:mt-16 lg:mb-10 grey-900 font-serif text-3xl">
        {title}
      </h3>

      <Grid as="div" className="xl:space-x-10">
        {blogsData?.map(({ full_slug, content }) => {
          if (!content?.header) return null;
          const { header, image } = content.header[0];
          return (
            <BlogPostPreview
              key={header}
              className="col-span-4"
              text={header}
              image={image.filename}
              imageAlt={image.alt}
              url={full_slug}
            />
          );
        })}
      </Grid>
    </div>
  );
};

export default BlogPreviewList;
