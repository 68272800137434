import React from 'react';
import storyblokImageResizeUrl from '../../../../lib/storyblok-image-resize';
import { Link } from 'gatsby';
import cn from 'classnames';
import { RightArrow } from '../../atoms/icons';

type Props = {
  text: string;
  image: string;
  imageAlt: string;
  url: string;
  className: string;
};

const BlogPostPreview: React.FC<Props> = ({ text, image, imageAlt = '', url, className }) => {
  return (
    <div
      className={cn(
        className,
        'w-full mb-10 lg:mb-16 border-b border-b-grey-300 pb-7 lg:pb-0 sm:border-0 last:border-0 flex flex-col',
      )}
    >
      <figure className="mb-4">
        <img
          className="w-full"
          src={`${storyblokImageResizeUrl(image, 340, 0)}`}
          alt={imageAlt}
          loading="lazy"
        />
      </figure>
      <h2 className={`font-serif grey-900 top-4 text-2xl flex-1 flex flex-col`}>
        <p className="line-clamp-2">{text}</p>
        <Link to={`/${url}`} className="text-blue-600 pt-5 mt-auto block text-sm">
          Read more <RightArrow className="inline" />
        </Link>
      </h2>
    </div>
  );
};

export default BlogPostPreview;
